@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-bevietnampro: 'Be Vietnam Pro', sans-serif;
}

body {
  font-family: var(--font-bevietnampro);
}

*,::after,::before{
  font-family:var(--font-bevietnampro), system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   letter-spacing: -0.015em;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

 

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}


a{
  cursor: pointer;
}

