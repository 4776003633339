@import "theme-colors";

// styles.scss
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  .bg-right-arrow {
    background-image: url("/images/icon-right-arrow.svg");
  }

  .bg-primary-600 {
    background-color: lighten(map-get($theme-colors, "primary"), 10%);
  }

  .bg-primary-700 {
    background-color: darken(map-get($theme-colors, "primary"), 5%);
  }

  .shadow-primary-200 {
    box-shadow: 0 0 0 0.2rem lighten(map-get($theme-colors, "primary"), 60%);
  }

  .bg-secondary-700 {
    background-color: darken(map-get($theme-colors, "secondary"), 5%);
  }

  .shadow-secondary-200 {
    box-shadow: 0 0 0 0.2rem lighten(map-get($theme-colors, "secondary"), 34%);
  }

  .bg-btn-grey {
    background-color: #D4D6EA;
  }

  .bg-error-bg {
    background-color: #f1d9dc;
  }
}

@layer utilities {
  .bg-size-200 {
    background-size: 200% 200%;
  }
  
  .bg-pos-0 {
    background-position: 0% 50%;
  }
  
  .bg-pos-100 {
    background-position: 100% 50%;
  }
}

body {
  margin: 0px;
  padding: 0px;
  
  background-color: #D4D6EA;
}
a, .link {
  @apply underline;
   color: map-get($theme-colors, "primary");
   
  &:hover, &:focus, &:visited {
   color: ighten(map-get($theme-colors, "primary"), 10%);
  }
  &.grey{
    color: slategray;
  }
}


h1 {
  @apply text-3xl font-semibold mb-5 mt-5;
}

h4 {
  @apply m-0 text-base font-medium;
}

.font-small {
  @apply text-sm;
}

/* .btn-primary {
  @apply text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md px-5 py-2.5 text-center me-0 mb-2 transition duration-300 ease-in-out bg-size-200 bg-pos-0 hover:bg-pos-100;
}
  */
  .btn{
     @apply text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-0 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium  px-5 py-2.5 text-center me-0 mb-2 transition duration-300 ease-in-out;
    background-size: 200% 200%;
    animation: gradientAnimation 5s ease infinite;
    &.btn-primary {
      @apply rounded-sm;
      &.btn-left{
        @apply rounded-r-none;
      }
      &.btn-right{
        @apply rounded-l-none;
      }
    
   }
   &:disabled {
    @apply bg-slate-500;
    animation: none;
    cursor: not-allowed;
    opacity: 0.6;
  }
  }

  
  
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

form {
  .control-group {
    @apply mb-5;

    label {
      @apply inline-block mb-1 rounded;
    }

    input {
      @apply block px-3 py-2 w-full;
    }

    &.error {
      label {
        @apply bg-error-bg text-danger;

        .message {
          @apply ml-1;
        }
      }
    }
  }
}

@mixin icon-mask($url, $color) {
  mask-image: url($url);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background-color: $color;
}

.icon{
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-bottom: -1px;
  &.icon-right-arrow, &.icon-left-arrow  {
    width: 20px;
   height: 12px;
    @include icon-mask("/images/icons/right-arrow-with-tail.svg", #fff);
  }
  &.icon-left-arrow{
    transform: scaleX(-1);
    margin-right: 4px;
  }
  &.icon-right-arrow{
     margin-left: 4px;
  }

}


/* Image Upload */

.zoom-image-container{
  transition: all 0.15s;
  &:not(&.selected-zoom-image-container){
     .zoom-image  {
  transition: all 0.15s;
}
 .zoom-text {
  transition: all 0.25s;
  line-height: 100%;
}
.zoom-text{
  margin-bottom: 2px;
}

&:hover {
  .zoom-image,.zoom-text{
    
    transform: scale(1.1);
  }
  .zoom-text{
    margin-top: 8px;
    margin-bottom: 0px;
  }
  
}
  }
  &.selected-zoom-image-container{
    @apply  bg-primary-500 text-white font-semibold;
    .zoom-image {
      transform: scale(1.1);
    }
    .zoom-text{
      margin-top: 8px;
      margin-bottom: -2px;
    }
    
  }

 
}


